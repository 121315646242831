import styled, { css } from "styled-components";
import { baseCaption, defaultFont } from "../../theme/typography";
import {
  blue300,
  cinnabar300,
  black600,
  selago,
  white,
} from "../../theme/colors";
import { bold } from "../../theme/typeface";
import { Link } from "react-router-dom";
import themeSizes from "../../theme/themeSizes";

const mql = window.matchMedia("(max-width: 600px)");
const mobileView = mql.matches;
const widthStart = mobileView ? "92%" : "86%";
const widthEnd = mobileView ? "100%" : "94%";

export const DashboardWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const SideNavStyle = styled.aside`
  min-width: ${({ expand }) => (expand ? "14%" : "6%")};
  background-color: #ffffff;
  position: sticky;
  cursor: pointer;
  top: 0;
  height: 100%;
  transition: min-width 0.2s;

  @media ${themeSizes.mobile} {
    min-width: ${({ expand }) => (expand ? "12%" : "0")};
  }

  img {
    margin-top: 18px;
    margin-left: 24px;
    transition: all 0.2s;
  }

  ul {
    top: 60px;
    width: 100%;
    padding: 0;
    list-style: none;
    position: absolute;
    height: calc(100vh - 110px);
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 0.6em;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dedede;
      // outline: 1px solid slategrey;
      border-radius: 20px;
      margin-left: 10px;
    }

    li {
      height: 50px;
      padding-left: 28px;
      padding-right: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;

      @media ${themeSizes.mobile} {
        padding-left: 8px;
      }
      ${baseCaption}
      svg {
        position: relative;
        float: left;
      }
      span {
        opacity: ${({ expand }) => (expand ? "1" : "0")};
        padding-left: 8px;
        display: grid;
        transition: opacity 0.2s;
      }
      &.active {
        svg {
          color: ${blue300};
        }

        ${(props) =>
          props.expand &&
          css`
            border-right: 4px solid ${blue300};
          `}
      }
    }
  }
`;

export const ContentWrapper = styled.section`
  min-width: ${({ expand }) => (expand ? widthStart : widthEnd)};
  max-height: 100%;
  background: ${mobileView ? white : selago};
  position: relative;
  transition: all 0.2s;
  .sticky-inner-wrapper {
    z-index: 5;
  }
`;

export const HorizontalBar = styled.div`
  background: ${white};
  box-shadow: 12px 4px 16px rgba(91, 127, 220, 0.2);
  max-height: 52px;
  padding: 12px;
  position: sticky;
  min-width: 92%;
  display: flex;
  justify-content: space-between;
  span {
    display: inline-block;
    svg {
      font-size: 28px;
      left: 6px;
      margin-top: 5px;
    }
  }
  div {
    span {
      ${defaultFont}
      color:${cinnabar300};
      cursor: pointer;
      vertical-align: super;
      margin-right: 12px;
    }

    svg {
      margin-top: 5px;
    }
  }
`;

export const ContentStyle = styled.div`
  min-height: 100vh;
  display: flex;
  padding: 0 24px;
  & > div {
    flex: 1;
  }
`;

export const TitleStyle = styled.div`
  ${defaultFont}
  font-weight: ${bold};
  margin: ${({ margin }) => margin || 0};
`;

export const Button = styled(Link)`
  border: 1px solid #212323;
  border-radius: 2px;
  background: ${(props) => props.background || "#212323"};
  color: ${(props) => props.color || "#FFFFFF"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: 300;
  padding: ${(props) => props.padding || "10px"};
  margin-left: ${({ align }) => align === "right" && `12px`};
  margin-right: ${({ align }) => align === "left" && `12px`};

  svg {
    vertical-align: -7px;
  }
`;
export const UploadButtonBlock = styled.span`
  position: ${(props) => props.position || "static"};
  right: ${(props) => props.right || "0px"};
  border: 1px solid transparent;
  border-radius: 2px;
  background: ${(props) => props.background || "#212323"};
  color: ${(props) => props.color || "#FFFFFF"};
  font-size: 16px;
  font-weight: 300;
  padding: ${(props) => props.padding || "6px 10px"};
  border: 1px solid ${(props) => props.borderColor || "transparent"};
  margin-left: ${({ align }) =>
    (align === "right" && `12px`) ||
    (align === "to right" && `150px`) ||
    (align === "right end" && `650px`)};
  margin-right: ${({ align }) => align === "left" && `12px`};
  cursor: pointer;
  svg {
    vertical-align: -7px;
    margin-right: 8px;
  }
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
    `}
`;

export const InvertButton = styled(Button)`
  color: #212323;
  background: #ffffff;
  border: 1px solid #212323;
  margin-left: ${({ align }) => align === "right" && `12px`};
  margin-right: ${({ align }) => align === "left" && `12px`};
`;
export const TitleContainer = styled(TitleStyle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ButtonContainer = styled.div`
  display: ${(props) => props.display || "flex"};
  margin-top: ${(props) => props.marginTop || "0px"};
  float: ${(props) => props.float || "auto"};
`;
export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #529165;
  border-radius: 5px;
  padding: 5px;
  justify-content: space-between;
  width: 100%;
  // height: 10%;
  margin-bottom: 5%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 5%;
  height: 40px;
  align-items: center;
  align-content: center;
`;
export const ShareTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  padding: 5px;
`;
export const Logo = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 25px;
`;
export const Middle = styled.div`
  width: 100%;
  background-color: transparent;
  // margin-bottom: 5%;
  justify-content: flex-end;
  float: right;
`;
export const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  height: 40px;
  border: 1px solid #d9d9d9;
  margin-bottom: 5%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
export const Bottom = styled.div`
  width: 60px;
  height: 60px;
  background-color: transparent;
  background-color: #2273cd;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
`;

export const CardMain = styled.div`
  width: 100%;
  margin-bottom: 2%;
  margin-top: 1%;
  background-color: transparent;
`;

export const Main = styled.div`
  height: 100%;
  align-items: center;
  align-content: center;
`;

export const Cards = styled.div`
  height: 15%;
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
`;

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: black;
`;

export const RSID = styled.p`
  font-size: 12px;
  color: black;
`;

export const OUTLET = styled.p`
  text-align: right;
  font-size: 10px;
  font-style: italic;
  color: black;
`;

export const LOCATION = styled.p`
  font-size: 12px;
  color: black;
  font-style: italic;
`;

export const First = styled.div`
  align-items: flex-end;
`;
export const Second = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
`;
export const Sub = styled.div``;

export const SubShare = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Third = styled.div`
  flex-direction: row;
  justify-content: space-between;
  margin-left: 2%;
  margin-bottom: 2%;
`;

export const CardButton = styled.div`
  height: 10%;
  display: flex;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  align-content: center;
  align-items: center;
  margin-bottom: 5%;
  box-shadow: 2px 2px 5px 0px #d1d1d1;
`;
export const CardEKYCButton = styled.div`
  height: 10%;
  display: flex;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  align-content: center;
  align-items: center;
  margin-bottom: 5%;
  background-color: #fff;
  box-shadow: 2px 2px 5px 0px #d1d1d1;
`;
export const CardButtonOpen = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

export const CalenderDiv = styled.div`
  width: 30%;
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  margin-right: 5%;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 10%;
  height: 40px;
  justify-content: space-between;
`;

export const Line = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  //   justify-content: center;
  align-items: center;
  margin-left: 5%;
  height: 40px;
`;

export const Location = styled.div`
  width: 90%;
  display: flex;
  flex: 0.5;
  flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  margin-left: 5%;
  //   height: 60px;
`;

export const Label = styled.p`
  marginright: 10px;
  margin: 0px;
  color: #9b9b9b;
`;

export const KYCstatusDone = styled.div`
  width: 50%;
  background-color: #33a853;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: "space-around";
  border-radius: 25px;
  align-items: center;
  margin-left: 5%;
`;
export const KYCstatusPending = styled.div`
  width: 50%;
  background-color: #f3b15f;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: "space-around";
  border-radius: 25px;
  align-items: center;
  margin-left: 5%;
`;


