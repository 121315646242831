/**
 * Typography - Barlow
 */

import { black400, black500, black700, white } from "./colors";
import { bold, light, regular, semiBold, xsBold } from "./typeface";

/** Default font */
export const defaultFontFamily = "barlow";

/** Default body style */
export const defaultFont = {
  fontFamily: defaultFontFamily,
  fontWeight: regular,
  fontSize: "16px",
  lineHeight: "140%",
};
/** Small body style */
export const smallFont = {
  fontFamily: defaultFontFamily,
  fontWeight: regular,
  fontSize: "12px",
  lineHeight: "120%",
};

export const defaultFontForgotPassword = {
  fontFamily: defaultFontFamily,
  fontWeight: regular,
  fontSize: "12px",
  lineHeight: "140%",
};

/**
 * Heading bold
 */
export const boldHeading = {
  xLarge: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "42px",
    lineHeight: "125%",
  },
  large: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "38px",
    lineHeight: "125%",
  },
  medium: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "36px",
    lineHeight: "125%",
  },
  small: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "32px",
    lineHeight: "125%",
  },
  regular: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "16px",
    lineHeight: "125%",
  },
};

/**
 * Heading regular
 */
export const regularHeading = {
  xLarge: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "30px",
    lineHeight: "140%",
  },
  large: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "28px",
    lineHeight: "140%",
  },
  medium: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "26px",
    lineHeight: "140%",
  },
  small: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "24px",
    lineHeight: "140%",
  },
  mini: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "20px",
    lineHeight: "140%",
  },
  supermini: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "16px",
    lineHeight: "100%",
  },
};

export const microHeading = {
  bold: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "18px",
    lineHeight: "140%",
  },
  regular: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "18px",
    lineHeight: "140%",
  },
  regularEKYC: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "13px",
    lineHeight: "140%",
  },
};

export const outletMediumCaption = {
  fontFamily: defaultFontFamily,
  fontWeight: xsBold,
  fontSize: "16px",
  lineHeight: "140%",
  color: black700,
};

export const outletEKYCMediumCaption = {
  fontFamily: defaultFontFamily,
  fontWeight: xsBold,
  fontSize: "14px",
  lineHeight: "120%",
  color: white,

};

export const outletDetailValue = {
  fontFamily: defaultFontFamily,
  fontSize: "14px",
  lineHeight: "140%",
};
export const outletDetailEKYCValue = {
  fontFamily: defaultFontFamily,
  fontSize: "11px",
  lineHeight: "120%",
};
export const baseCaption = {
  fontFamily: defaultFontFamily,
  fontWeight: regular,
  fontSize: "14px",
  lineHeight: "140%",
};

export const lightCaption = {
  fontFamily: defaultFontFamily,
  fontWeight: light,
  fontSize: "14px",
  lineHeight: "140%",
  color: black400,
};
export const lightEKYCCaption = {
  fontFamily: defaultFontFamily,
  fontWeight: light,
  fontSize: "14px",
  lineHeight: "140%",
  color: white,
};
export const backCaption = {
  fontFamily: defaultFontFamily,
  fontWeight: light,
  fontSize: "14px",
  lineHeight: "140%",
  color: black500,
};

export const miniCaption = {
  bold: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "12px",
    lineHeight: "140%",
  },
  regular: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "12px",
    lineHeight: "140%",
  },
  regularUpperCase: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "12px",
    lineHeight: "140%",
    textTransform: "uppercase",
  },
  semiBold: {
    fontFamily: defaultFontFamily,
    fontWeight: semiBold,
    fontSize: "12px",
    lineHeight: "140%",
  },
};

export const microCaption = {
  bold: {
    fontFamily: defaultFontFamily,
    fontWeight: bold,
    fontSize: "10px",
    lineHeight: "140%",
  },
  regular: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "10px",
    lineHeight: "140%",
  },
  regularUpperCase: {
    fontFamily: defaultFontFamily,
    fontWeight: regular,
    fontSize: "10px",
    lineHeight: "140%",
    textTransform: "uppercase",
  },
};

// FONT-SIZES
export const font = {
  micro: "10px",
  mini: "12px",
  base: "14px",
  default: "16px",
  small: "18px",
  smallmedium: "20px",
  smallLarge: "24px",
  mediumSmall: "26px",
  medium: "28px",
  xmedium: "30px",
  largeSmall: "32px",
  largeMedium: "36px",
  large: "38px",
  xLarge: "42px",
};

//line-height
export const lineHeight = {
  regular: "125%",
  medium: "140%",
};

