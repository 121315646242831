import React, {Component, Fragment, useState} from "react";
import FileUpload from "../../../components/FileUpload";
import {RETAILERS_OUTLET_WORKFLOW_UPDATE,} from "../../../helpers/constants/apiUrl";
import {triggerSimpleAjax} from "../../../helpers/httpHelper";
import {black100, black600} from "../../../theme/colors";
import {DashboardTextBox} from "../../dashboard/components/DashboardTexBox";
import {FormContainer} from "../../dashboard/components/FormContainer";
import {FormRow} from "../../dashboard/components/FormRow";
import styled from "styled-components";
import triggerNotification from "../../../actions/triggerNotification";
import {RETAILERS} from "../../../helpers/constants/routes";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {bold} from "../../../theme/typeface";
import {Regex} from "../../../helpers/utils/regEx";
import {ImageHandler} from "../../dashboard/outlets/OutletTotalImages"
import ImageGallery from "../../../components/image_gallery/ImageGallery";

const Bold = styled.p`
  font-weight: ${bold};
  color: ${black600};
  font-size: 18px;
`;
const ImagePill = styled.span`
  height: 20px;
  padding: 4px;
  border-radius: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
  cursor: default;
  font-size: 14px;
  line-height: 22px;
  background-color: ${black100};

  b {
    margin: 0 4px;
    display: inline-block;
    cursor: pointer;
  }
`;
const ImageContent = ({name, index, deleteImage, type}) => (
  <ImagePill key={`image-${index}`}>
    {name}
    <b
      onClick={() => {
        deleteImage({deleteIndex: index, type: type});
      }}
    >
      x
    </b>
  </ImagePill>
);

class RetailersOutletEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bank_related_photo_proofs: [],
      gst_number_photo_proofs: [],
      ownership_proof: [],
     address_proof: [],
      pan_number_photo_proofs: [],
      error: {},
      isGstDisabled: false,
      isAccDisabled: false,
      isPanDisabled: false,
      showPanValidationError: false,
      showGstValidationError: false,
      showIfscValidationError: false,
      showAccValidationError: false,
      galleryImage:null,
      showGallery: false,
    };

    this.setShowGallery = this.setShowGallery.bind(this)
  }

  setShowGallery(value) {
    this.setState({
      showGallery: value,
    })
  }

  componentDidMount() {
    const {inProps} = this.props;
    let details;
    if (inProps) {
      details = this.props.details;
    } else {
      const {location} = this.props;
      const {state} = location;
      details = state.details;
    }

    const {
      dob,
      email,
      address,
      pin_code,
      phone_number1,
      phone_number2,
      account_number,
      bank_account_type,
      account_holder_name,
      bank,
      ifsc_code,
      gst_number,
      pan_number,
    } = details;
    this.setState({
      dob,
      email,
      address,
      pin_code,
      phone_number1,
      phone_number2,
      bank_account_type,
      account_number: details.related_workflow_object
        ? details.related_workflow_object.account_number
        : null,
      // bank_account_type: details.related_workflow_object
      //   ? details.related_workflow_object.bank_account_type
      //   : null,
      account_holder_name: details.related_workflow_object
        ? details.related_workflow_object.account_holder_name
        : null,
      bank: details.related_workflow_object
        ? details.related_workflow_object.bank
        : null,
      ifsc_code: details.related_workflow_object
        ? details.related_workflow_object.ifsc_code
        : null,
      gst_number,
      pan_number,
      outlet_details: details
    });
  }

  onChangeAction = (e, key) => {

    if ((Regex.Ifsc).test(e.target.value)) {
      this.setState({showIfscValidationError: false})
    }


    if (e.target.name === "pan_number") {
      if ((Regex.Pan).test(e.target.value)) {
        this.setState({showPanValidationError: false})
      }
      this.setState({isPanDisabled: true});
    }
    if (e.target.name === "gst_number") {
      if ((Regex.Gst).test(e.target.value)) {
        this.setState({showGstValidationError: false})
      }
      this.setState({isGstDisabled: true});
    }
    if (e.target.name === "account_number") {
      if ((Regex.Acc).test(e.target.value)) {
        this.setState({showAccValidationError: false})
      }
      this.setState({isAccDisabled: true});
    }
    e && e.preventDefault && e.preventDefault();
    const isOrgOrRoleKey =
      typeof e === "object" && (e.name === "organisation" || e.name === "role");
    let stateValue = {};
    if (!e && key) {
      stateValue = {
        [key]: [],
      };
    } else if (Array.isArray(e)) {
      let nameVal;
      let temp = [];
      e.map(({name, value}) => {
        nameVal = name;
        temp = [...temp, value];
      });
      stateValue = {
        [nameVal]: [...temp],
      };
    } else {
      const {name, value} = e.target || e;
      stateValue = {
        [name]: value,
      };
    }
    if (!isOrgOrRoleKey) {
      this.setState({
        ...stateValue,
      });
    }
  };
  navigatePrevious = (e) => {
    e && e.preventDefault();
    this.props.history.goBack(RETAILERS.RETAILERS_OUTLETS_INFO);
  };

  onSubmitAction = (e) => {
    e && e.preventDefault();
    const {history, notificationHandler} = this.props;
    const slug = (history.location.state || {}).details.slug;
    const {
      address,
      pin_code,
      phone_number1,
      phone_number2,
      account_number,
      account_holder_name,
      bank,
      ifsc_code,
      gst_number,
      pan_number,
      bank_related_photo_proofs,
      gst_number_photo_proofs,
      pan_number_photo_proofs,
      ownership_proof,
      address_proof,
      showPanValidationError,
      showGstValidationError,
      showAccValidationError,
      showIfscValidationError,
    } = this.state;

    let bank_proof =
      bank_related_photo_proofs || []
        ? bank_related_photo_proofs.map((item, inx) => {
          return item.id;
        })
        : [];
    let gst_proof =
      gst_number_photo_proofs || []
        ? gst_number_photo_proofs.map((item, inx) => {
          return item.id;
        })
        : [];
    let pan_proof =
      pan_number_photo_proofs || []
        ? pan_number_photo_proofs.map((item, inx) => {
          return item.id;
        })
        : [];
        let ownership_proofs =
        ownership_proof || []
          ? ownership_proof.map((item, inx) => {
            return item.id;
          })
          : [];
          let address_proofss =
          address_proof || []
          ? address_proof.map((item, inx) => {
            return item.id;
          })
          : [];

    const requestValues = {
      data: {
        address,
        pin_code,
        phone_number1,
        phone_number2,
        gst_number,
        pan_number,
        account_number,
        account_holder_name,
        bank,
        ifsc_code,
        ownership_proof:ownership_proofs,
        address_proof:address_proofss,
        bank_related_photo_proofs: bank_proof,
        gst_number_photo_proofs: gst_proof,
        pan_number_photo_proofs: pan_proof,
      },
    };


    if (pan_number && !(Regex.Pan).test(pan_number)) {
      this.setState({showPanValidationError: true})
    } else {
      this.setState({showPanValidationError: false})
    }

    if (gst_number && !(Regex.Gst).test(gst_number)) {
      this.setState({showGstValidationError: true})
    } else {
      this.setState({showGstValidationError: false})
    }

    if (ifsc_code && !(Regex.Ifsc).test(ifsc_code)) {
      this.setState({showIfscValidationError: true})
    } else {
      this.setState({showIfscValidationError: false})
    }

    if (account_number && !(Regex.Acc).test(account_number)) {
      this.setState({showAccValidationError: true})
    } else {
      this.setState({showAccValidationError: false})
    }

    if (!showPanValidationError && !showGstValidationError && !showAccValidationError && !showIfscValidationError) {
      triggerSimpleAjax(
        RETAILERS_OUTLET_WORKFLOW_UPDATE(slug),
        "PATCH",
        requestValues,
        (response) => {
          notificationHandler({
            messageText: `Update request submitted successfully!!`,
            status: "SUCCESS",
          });
          history.push(RETAILERS.RETAILERS_OUTLETS_INFO, {
            outletSlug: slug,
          });
        },
        (error) => {
          this.setState({error});
        }
      );
    }
  };
  deleteImage = ({deleteIndex, type}) => {
    if (type === "bank_related_proofs") {
      let {bank_related_photo_proofs} = this.state;
      bank_related_photo_proofs = [
        ...bank_related_photo_proofs.slice(0, deleteIndex),
        ...bank_related_photo_proofs.slice(deleteIndex + 1),
      ];

      this.setState({bank_related_photo_proofs});
    } else if (type === "pan_related_proofs") {
      let {pan_number_photo_proofs} = this.state;
      pan_number_photo_proofs = [
        ...pan_number_photo_proofs.slice(0, deleteIndex),
        ...pan_number_photo_proofs.slice(deleteIndex + 1),
      ];

      this.setState({pan_number_photo_proofs});
    } else if (type === "gst_related_proofs") {
      let {gst_number_photo_proofs} = this.state;
      gst_number_photo_proofs = [
        ...gst_number_photo_proofs.slice(0, deleteIndex),
        ...gst_number_photo_proofs.slice(deleteIndex + 1),
      ];

      this.setState({gst_number_photo_proofs});
    }
    else if (type === "ownership_proofs") {
      let {ownership_proof} = this.state;
      ownership_proof = [
        ...ownership_proof.slice(0, deleteIndex),
        ...ownership_proof.slice(deleteIndex + 1),
      ];

      this.setState({ownership_proof});
    }
    else if (type === "address_proofss") {
      let {address_proof} = this.state;
      address_proof = [
        ...address_proof.slice(0, deleteIndex),
        ...address_proof.slice(deleteIndex + 1),
      ];

      this.setState({address_proof});
    }
  };
  

  render() {
    const {
      address,
      pin_code,
      email,
      dob,
      phone_number1,
      phone_number2,
      bank_account_type,
      account_number,
      account_holder_name,
      bank,
      ifsc_code,
      gst_number,
      pan_number,
      state = [],
      cities = [],
      city = [],
      states = [],
      ownership_proof,
      address_proof,
      bank_related_photo_proofs,
      gst_number_photo_proofs,
      pan_number_photo_proofs,
      error = {},
      isGstDisabled,
      isAccDisabled,
      isPanDisabled,
      showPanValidationError,
      showGstValidationError,
      showIfscValidationError,
      showAccValidationError,
      outlet_details
    } = this.state;

    const {
      changed_fields,
      data_to_update,
      isQcEditForm = false,
      inProps = false
    } = this.props;

    const fieldNames = [
      {
        name: "Address",
        value: address,
        fieldName: "address",
        placeholder: "Your Address"
      },
      {
        name: "Pincode",
        value: pin_code,
        fieldName: "pin_code",
        placeholder: "Pincode"
      },
      {
        name: "Email",
        value: email,
        fieldName: "email",
        placeholder: "Email"
      },
      {
        name: "Phone Number 1",
        value: phone_number1,
        fieldName: "phone_number1",
        placeholder: "Phone Number 1"
      },
      {
        name: "Phone Number 2",
        value: phone_number2,
        fieldName: "phone_number2",
        placeholder: "Phone Number 2"
      },
      {
        name: "Date of Birth",
        value: dob,
        fieldName: "dob",
        placeholder: "Date of Birth"
      },

      {
        insertTitle: "Bank Information"
      },
      {
        name: "Bank Account Type",
        value:  bank_account_type?.find((o) => o.show_ownership_proof_image === true).type,
        fieldName: "bank_account_type",
        placeholder: "Bank Account Type"
      },
      {
        name: "Account Number",
        value: account_number,
        fieldName: "account_number",
        placeholder: "Account Number"
      },
      {
        name: "Bank Name",
        value: bank,
        fieldName: "bank",
        placeholder: "bank"
      },
      {
        name: "IFSC Code",
        value: ifsc_code,
        fieldName: "ifsc_code",
        placeholder: "IFSC Code"
      },
      {
        name: "Cheque Book",
        type: "bank_related_proofs",
        value: bank_related_photo_proofs,
        outletFieldName: "bank_related_photo_proofs",
        imageInput: true
      },
      {
        insertTitle: "Tax Information"
      },
      {
        name: "Ownership Proof",
        value: ownership_proof,
        fieldName: "ownership_proofs",
        outletFieldName: "ownership_proof",
         imageInput: true
      },
      {
        name: "Address Proof",
        value: address_proof,
        fieldName: "address_proofss",
        outletFieldName: "address_proof",
         imageInput: true
      },
      {
        name: "GST No",
        value: gst_number,
        fieldName: "gst_number",
        placeholder: "GST No"
      },
      {
        name: "GST File",
        type: "gst_related_proofs",
        value: gst_number_photo_proofs,
        outletFieldName: "gst_number_photo_proofs",
        imageInput: true
      },
      {
        name: "PAN",
        value: pan_number,
        fieldName: "pan_number",
        placeholder: "PAN No"
      },
      {
        name: "Pan Upload",
        type: "pan_related_proofs",
        outletFieldName: "pan_number_photo_proofs",
        value: pan_number_photo_proofs,
        imageInput: true
      },
    ]

    const compareImageStyles = {
      padding: "20px",
      objectFit: 'contain',
      minWidth: "50px",
      maxWidth: "150px",
      maxHeight: "150px",
      minHeight: "50px",

    }

    return (
      <Fragment>
        <FormContainer
          title={"Edit Outlet Information"}
          className="text"
          footerLocation={"56%"}
          actionButtons={inProps}
          actionButtonText={"Save" }
          onClickAction={this.onSubmitAction}
          onCancelAction={this.navigatePrevious}
        >
          {/* <FormRow leftSideWidth={"25%"} name="Address" /> */}
          {fieldNames.map(field => {
            const {
              insertTitle,
            } = field
            if (insertTitle) {
              return (
                <Bold>{insertTitle}</Bold>
              )
            }

            const fieldChangedValue = changed_fields && changed_fields.includes(field.fieldName) && `Changed To: ${data_to_update[field.fieldName]}`
            return (
              <FormRow
                leftSideWidth="25%"
                name={field.name || field.fieldName }
                rightElement={
                  field.imageInput ? (!isQcEditForm ? (
                    <FileUpload
                      fileAcceptType="image/*"
                      // errorMessage={
                      //   bank_related_photo_proofs.length >= 3 ? true : false
                      // }
                      // disabled={
                      //   isAccDisabled ? bank_related_photo_proofs.length >= 3 : true
                      // }
                      customValue={field["value"].map((image, index) => (
                        <ImageContent
                          id={image.id}
                          name={image.name}
                          index={index}
                          deleteImage={this.deleteImage}
                          type={field.type}
                        />
                      ))}
                      onFileChosen={(id, fileName) => {
                        field["value"].push({id: id, name: fileName});
                        this.setState({[field.type]: field["value"]});
                      }}
                    />
                  ) : (
                    <>
                      {
                        changed_fields.includes(field.outletFieldName) && (<>
                          <>
                            From :
                            {outlet_details && (outlet_details[field.outletFieldName] || []).map(item => <ImageHandler
                              imageData={item} width={100} height={100} onImageOpenClick={(item) => {
                                this.setState({
                                  galleryImage: [item],
                                  showGallery: true,
                                })
                            }}
                            />)}
                          </>
                          <>
                            To:
                            {
                              (data_to_update[field.outletFieldName] || []).map(item => <ImageHandler
                                imageData={item} width={100} height={100} onImageOpenClick={(item) => {
                                this.setState({
                                  galleryImage: [item],
                                  showGallery: true,
                                })
                              }}
                              />)
                            }
                          </>
                        </>)
                      }
                    </>
                  )) : (
                    <DashboardTextBox
                      type={"text"}
                      value={field.value}
                      placeholder={`Enter ${field.placeholder}`}
                      fieldName={field.fieldName}
                      onChange={this.onChangeAction}
                      warnMessage={{
                        text: error[field.name] || fieldChangedValue,
                        onlyTextError: true
                      }}
                      disabled={changed_fields}
                    />
                  )
                }
              />
            )
          })}
        </FormContainer>
        {this.state.showGallery && (
          <ImageGallery
            images={this.state.galleryImage}
            setShowGallery={this.setShowGallery}
            noExit={false}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notificationHandler: bindActionCreators(triggerNotification, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(RetailersOutletEdit);

