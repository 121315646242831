import React, { useEffect, useRef, useState } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

const ImageViewer = ({ invoiceImages, skuImages, onClose,zoomLevel,addonSKUImage }) => {
  const viewerRef = useRef(null);
  const viewerInstance = useRef(null);
  const [activeImages, setActiveImages] = useState([]); // State to hold the images to be viewed
  const [isViewerOpen, setIsViewerOpen] = useState(false); // State to control viewer visibility

  useEffect(() => {
    if (viewerRef.current && isViewerOpen && activeImages.length > 0) {
      // Initialize Viewer.js with the activeImages
      viewerInstance.current = new Viewer(viewerRef.current, {
        inline: false, // Display in modal
        viewed() {
          viewerInstance.current.zoomTo(zoomLevel);
        },
        hidden() {
          // Only close the viewer, do not clear images
          if (onClose) onClose();
          setIsViewerOpen(false); // Hide the viewer without clearing images
        },
      });

      // Automatically open the first image in the modal
      viewerInstance.current.show();
    }

    // Cleanup the viewer instance when component unmounts or when switching images
    return () => {
      if (viewerInstance.current) {
        viewerInstance.current.destroy();
      }
    };
  }, [activeImages, isViewerOpen, onClose,zoomLevel]);

  // Programmatically close the viewer
  const closeViewer = () => {
    if (viewerInstance.current) {
      viewerInstance.current.hide(); // Close the viewer
    }
  };

  // Function to handle opening the viewer with specific images
  const openViewer = (images) => {
    setActiveImages(images); // Set the images to view
    setIsViewerOpen(true); // Open the viewer modal
  };

  return (
    <div>
  {invoiceImages.length === 0 && skuImages.length === 0 && addonSKUImage.length === 0 ? (
    <p>No images found for the invoice</p>
  ) : (
    <>
      <h3>Invoice Images</h3>
      <div>
        {invoiceImages.length > 0 ? (
          invoiceImages.map((image, index) => (
            <img
              key={`invoice-${index}`}
              src={image}
              alt={`invoice-img-${index}`}
              style={{ width: 100, height: 100, cursor: 'pointer', margin: 5 }}
              onClick={() => openViewer(invoiceImages)} // Open invoice images on click
            />
          ))
        ) : (
          <p>No Invoice Images</p>
        )}
      </div>

      <h3>SKU Images</h3>
      <div>
        {skuImages.length > 0 ? (
          skuImages.map((image, index) => (
            <img
              key={`sku-${index}`}
              src={image}
              alt={`sku-img-${index}`}
              style={{ width: 100, height: 100, cursor: 'pointer', margin: 5 }}
              onClick={() => openViewer(skuImages)} // Open SKU images on click
            />
          ))
        ) : (
          <p>No SKU Images</p>
        )}
      </div>

      <h3>Add On SKU Images</h3>
      <div>
        {addonSKUImage.length > 0 ? (
          addonSKUImage.map((image, index) => (
            <img
              key={`addon-sku-${index}`}
              src={image}
              alt={`addon-sku-img-${index}`}
              style={{ width: 100, height: 100, cursor: 'pointer', margin: 5 }}
              onClick={() => openViewer(addonSKUImage)} // Open Add On SKU images on click
            />
          ))
        ) : (
          <p>No Add On SKU Images</p>
        )}
      </div>
    </>
  )}

  {/* Hidden image elements for viewer */}
  {isViewerOpen && (
    <div ref={viewerRef} style={{ display: 'none' }}>
      {/* Render only the Active Images */}
      {activeImages.map((image, index) => (
        <img key={`view-${index}`} src={image} alt={`view-img-${index}`} />
      ))}
    </div>
  )}

  {isViewerOpen && (
    <button onClick={closeViewer}>Close</button> // Button to close the viewer
  )}
</div>

  );
};

export default ImageViewer;
