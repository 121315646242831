import React, { Component } from "react"
import RetailersOutletEdit from "../../retailers-dashboard/retailers-outlets/RetailersOutletEdit";

class RetailerEdit extends Component{
  state = {
    changed_fields: [],
    data_to_update: {}
  }

  componentDidMount() {
    const { related_workflow_object } = this.props.outletDetails
    const { other_config } = related_workflow_object
    const isEmpty = Object.keys(other_config).length === 0;
    if(!isEmpty){
      const changed_fields = other_config?.changed_fields?.split(",")
      const data_to_update = JSON.parse(other_config?.data_to_update)
      this.setState({
        changed_fields,
        data_to_update,
      })}
  }

  render() {
    const { outletDetails,getOutletDetails } = this.props

    const {
      data_to_update,
      changed_fields
    } = this.state;
    return (
      <RetailersOutletEdit
        details={outletDetails}
        changed_fields={changed_fields}
        data_to_update={data_to_update}
        getOutletDetails={getOutletDetails}
        isQcEditForm
        inProps
      />
    )
  }
}

export default RetailerEdit
