import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BackArrow from "./arrow_back_ios.png"
import LocalWhite from "./local_activity_white.svg";
import { useHistory } from 'react-router-dom';
import DateRangeIcon from "./date_range.svg";
import InfoICon from "./info.svg";
import EventAvailableIcon from "./event_available.svg";
import RectangleIcon from "./rectangle_37.svg";
import { saveAs } from 'file-saver';
import getMobileViewTarget from '../../../actions/creation/getTragetDetails';

const viewPDF = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    window.open(objectURL, '_blank');
  } catch (error) {
    console.error('Error viewing the PDF', error);
  }
};

export const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const Program = styled.div`
  background-color: #004080;
  width: 100%;
  height: 40px;
  left: 15px;
  top: 137px;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    height: 30px;
    font-size: 14px;
    padding: 5px;
  }
`;

export const ProgramText = styled.span`
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 8px;
    line-height: 12px;
  }
`;

export const Card = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 5px 5px #D9D9D9;
  height: auto;
  background-image: linear-gradient(to bottom, #004080 17%, white 10%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    padding: 10px;
    margin-bottom: 5px;
    box-shadow: none;
  }
`;

export const Details = styled.div`
  margin-top: 50px;
  p {
    margin: 5px 0;
    font-size: 14px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const DesktopMessage = styled.div`
  color: red;
  font-size: 1.2em;
  margin-bottom: 20px;
  padding-left: 33rem;

  @media (max-width: 600px) {
    padding-left: 0;
    font-size: 1em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto; /* Pushes the button to the bottom of the card */

  @media (max-width: 600px) {
    justify-content: flex-end;
    margin-top: 10px; /* Adjust margin for smaller screens */
  }
`;

export const StyledButton = styled.button`
  width: 70px;
  height: 30px;
  background-color: #2E487B;
  border-radius: 5px;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  margin-top: 10px;

  @media (max-width: 600px) {
    width: 60px;
    height: 25px;
    font-size: 12px;
    margin-top: 5px;
  }
`;

// Centered message style
const CenteredMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top:60%
`;

const TargetVsAch = () => {
  const history = useHistory();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);
  const [targetVsAchDetails, setTargetVsAchDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const url = window.location.pathname;
    const req = url.split('slug')[1];
    // const req = "gcp-and-gcx-payout-payout-CSZBqAgoH3VayEg1a9cC/1349145-agcako3000001550322611";
    getMobileViewTarget(req).then((response) => {
      const { results } = response;
      setTargetVsAchDetails(results);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
    });
  }, []);

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <Container>
      {isDesktop ? (
        <DesktopMessage>Please switch to mobile view for a better experience</DesktopMessage>
      ) : (
        <>
          <h3 style={{ color: "#2E487B", marginLeft: "5px", display: "flex", alignItems: "center" }}>
            <img src={BackArrow} alt="Back" style={{ marginRight: "15px" }} onClick={handleBackClick} />
            Target vs Achievement
          </h3>
          {isLoading ? (
            <div>Loading...</div>
          ) : targetVsAchDetails.length === 0 ? (
            <CenteredMessage>
              <div>No data available</div>
            </CenteredMessage>
          ) : (
            targetVsAchDetails.map((detail, index) => (
              <React.Fragment key={index}>
                <Card>
                  <p style={{ color: "#FFFFFF", marginTop: "-1px", fontSize: "11px", lineHeight: "20px", fontWeight: "700" }}>
                    {detail?.report_name || 'N/A'}
                  </p>
                  <Details>
                    <p style={{ fontWeight: "600", fontSize: "11px", lineHeight: "20px", color: "#000000" }}>
                      {detail?.report_name || 'N/A'}
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "11px", lineHeight: "20px", color: "#000000" }}>
                      RSID - {detail?.rsid || 'N/A'}
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "11px", lineHeight: "20px", color: "#000000" }}>
                      Program - {detail?.program || 'N/A'}
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "11px", lineHeight: "20px", color: "#000000" }}>
                      Duration - {detail?.duration || 'N/A'}
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "11px", lineHeight: "20px", color: "#000000" }}>
                      Remarks - {detail?.remark || 'N/A'}
                    </p>
                  </Details>
                  <ButtonContainer>
                    <StyledButton onClick={() => viewPDF(detail?.url)}>View</StyledButton>
                  </ButtonContainer>
                </Card>
              </React.Fragment>
            ))
          )}
        </>
      )}
    </Container>
  );
};

export default TargetVsAch;
