import React, { Fragment, Component } from "react";
import { withLastLocation } from "react-router-last-location";
import { FormContainer } from "../components/FormContainer";
import { FormRow } from "../components/FormRow";
import {
  green300,
  completed,
  processing,
  accepted,
  cancelled,
  cinnabar300,
  curiousBlue300,
  white,
} from "../../../theme/colors";
import { SelectFilter } from "../components/SelectFilter";
import DatePicker from "../../../components/DatePicker";
import { DashboardTextBox } from "../components/DashboardTexBox";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { bindActionCreators } from "redux";
import triggerNotification from "../../../actions/triggerNotification";
import { connect } from "react-redux";
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import ReactTable from "../../../components/table";
import { AnchorText } from "../components/ProjectCard";
import CheckIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";
import { StatusTagStyle } from "../../../components/StatusTagStyle";
import {
  GET_OUTLET_RECOVERY_DOWNLOAD_TABLE_DATA,
  OUTLET_RECOVERY_AUDITOR,
  OUTLET_RECOVERY_PROJECT,
  OUTLET_RECOVERY_SUBPROJECT,
} from "../../../helpers/constants/apiUrl";
import { ConfirmationModal } from "../components/ConfirmationModal";
import CheckBox from "../../../components/ui/CheckBox";
import processTable from "../../../actions/processOutlet";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import { CSVLink, CSVDownload } from "react-csv";
import appendToSearch from "../../../helpers/utils/appendToSearch";
import {
  getOutletRecoveryTableData,
  getOutletRecoveryAddedTableData,
  getOutletRecoveryAcceptStatus,
  getOutletRecoveryRejectStatus,
} from "../../../actions/getOutletData";
const Bold = styled.p`
  font-weight: bold;
`;
class OutletFieldRecovery extends Component {
  state = {
    date_uploaded: "--Select Your Date--",
    error: null,
    hasError: false,
    project_error: "",
    sub_project_error: "",
    date_uploaded_error: "",
    rsid_assigned_error: "",
    rsid_validation_error: "",
    rsid_value: "",
    auditor_error: "",
    date_error: "",
    date_validation_error: "",
    date_limit: null,
    projectsData: [],
    tableData: [],
    csvData: [],
    csvHeaderData: [],
    count: 1,
    previous: 1,
    next: 1,
    current: 1,
    pageSize: 50,
    isLoading: false,
    open: false,
    confirmationAction: null,
    confirmationText: `Confirming will create  these in the master.
This cannot be rolled back.`,
    isLoading: false,
    tickOrCancelClicked: false,
    subprojectsData: [
      {
        name: "No Item",
        id: 127,
      },
    ],
    auditorData: [
      {
        name: "No Item",
        id: 127,
      },
    ],
    formValues: {},
    project_id: 121,
    sub_project_id: 397,
    users: [
      {
        slug: "k8ftksajwSKENFkvE947",
        name: "Project Demo",
        add_data_on_fly: true,
        is_validation_calling_necessary: true,
        id: 127,
        organisation: 76,
      },
      {
        slug: "k8ftksajwSKENFkvE947",
        name: "Project Demo 2",
        add_data_on_fly: true,
        is_validation_calling_necessary: true,
        id: 129,
        organisation: 76,
      },
    ],
  };
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
  }

  componentDidMount() {
    this.getProjectData();
    this.triggerTableData();
    // var date = new Date();
    // let currentDay = String(date.getDate()).padStart(2, "0");
    // let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    // let currentYear = date.getFullYear();
    // let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    // this.setState({
    //   date_uploaded: currentDate,
    // });
  }
  componentDidUpdate(prevProps) {
    const { location, refreshData, uploadedId } = this.props;
    if ((prevProps.refreshData === false && refreshData) || location.search !== prevProps.location.search || prevProps.uploadedId !== uploadedId) {
        this.setState({ isLoading: true })
        this.triggerTableData(location.search.split("=").pop());
    }
}

  getProjectData = (method = "GET") => {
    let reqObj = "";
    triggerSimpleAjax(OUTLET_RECOVERY_PROJECT, method, reqObj, (response) => {
      this.setState(
        {
          projectsData: response,
          count: response.count,
          isLoading: false,
        },
        () => {
          console.log(this.state.dealersOverallTotal, "dealersOverallTotal1");
        }
      );
    });
    console.log("action--", this.state.projectsData);
  };
  getAuditorData = (sub_project_id) => {
    let reqObj = "";
    triggerSimpleAjax(
      OUTLET_RECOVERY_AUDITOR + "?sub_project_id=" + sub_project_id,
      "GET",
      "",
      (response) => {
        this.setState(
          {
            auditorData: response,
            count: response.count,
            isLoading: false,
          },
          () => {
            console.log(this.state.auditorData, "dealersOverallTotal1");
          }
        );
      }
    );
  };

  getSubProjectData = (project_id) => {
    let reqObj = "";
    triggerSimpleAjax(
      OUTLET_RECOVERY_SUBPROJECT + "?project_id=" + project_id,
      "GET",
      "",
      (response) => {
        this.setState(
          {
            subprojectsData: response,
            count: response.count,
            isLoading: false,
          },
          () => {
            console.log(this.state.subprojectsData, "dealersOverallTotal1");
          }
        );
      }
    );
  };
 
  triggerCompleted = (id) => {
    this.setState({
      tickOrCancelClicked: true,
    });
    this.processTableAction(id, true);
  };

  triggerCancelled = (id) => {
    this.setState({
      tickOrCancelClicked: true,
    });
    this.processTableAction(id, false);
  };
  onPageChange = (pageNumber, b, c, d) => {
    const { history, location } = this.props;
    history.push({
      route: "/",
      // state: { ...history.location.state },
      state: { ...history.location.state},
      search: appendToSearch(location.search, {
        page: pageNumber,
      }),
    });
  };
  triggerTableData = (pageSize=50) => {
    this.setState({ isLoading: true });
    const { location } = this.props;
    getOutletRecoveryTableData(pageSize, this.state.current)
      .then((response) => {
        this.setState({
          tableData: [...response.results],
          count: response.count,
          previous: response.previous,
          next: response.next,
          current: response.current_page_number,
          pageSize: response.page_size,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({ isLoading: false });
      });
  };
  processTableAction = (outlet_recovery_id, isActivate) => {
    const { location } = this.props;
    // let projId = get(location, "state.subProjectId");
    let projId = "";

    this.setState({
      open: true,
      confirmationText: isActivate ? (
        <div>
          {`Confirming will create  these  in the project.
This cannot be rolled back.`}{" "}
          <Bold>
            {`Any upload in this project will be cancelled`}{" "}
          </Bold>
        </div>
      ) : (
        `Confirming will cancel the job. This cannot be rolled back.`
      ),
      confirmationAction: () => {
        if (isActivate) {
          this.props.notificationHandler({
            messageText: "Started Outlet Recovery for found outlets.",
            status: "SUCCESS",
          });
          getOutletRecoveryAcceptStatus(outlet_recovery_id)
            .then((response) => {
              this.props.notificationHandler({
                messageText: response.detail,
                status: "SUCCESS",
              });
              this.setState(
                {
                  open: false,
                  confirmationAction: null,
                  tickOrCancelClicked: false,
                },
                () => {
                  this.triggerTableData();
                }
              );
            })
            .catch((error) => {
              this.setState(
                {
                  open: false,
                  confirmationAction: null,
                  tickOrCancelClicked: false,
                },
                () => {
                  this.props.notificationHandler({
                    messageText: error.detail,
                    status: "FAILED",
                  });
                }
              );
            });
        } else {
          getOutletRecoveryRejectStatus(outlet_recovery_id)
            .then((response) => {
              this.props.notificationHandler({
                messageText: response.detail,
                status: "SUCCESS",
              });
              this.setState(
                {
                  open: false,
                  confirmationAction: null,
                  tickOrCancelClicked: false,
                },
                () => {
                  this.triggerTableData();
                }
              );
            })
            .catch((error) => {
              this.setState(
                {
                  open: false,
                  confirmationAction: null,
                  tickOrCancelClicked: false,
                },
                () => {
                  this.props.notificationHandler({
                    messageText: error.detail,
                    status: "FAILED",
                  });
                }
              );
            });
        }
      },
    });
  };
  render() {
    const onChangeAction = (e) => {
      if (e.length === 0) {
        this.setState({
          ...this.state,
          rsid_validation_error: "RSID Must be Seleted",
          rsid_value:""
        });
      } else {
        let arr = [];
        arr.push(e);
        const newArr = arr[0].split(",").map((x) => x.trim());
        this.setState({
          ...this.state,
          rsid_assigned_error: "",
          rsid_validation_error: "",
          rsid_value: e,
          formValues: {
            ...this.state.formValues,
            rsid_list: newArr,
          },
        });
      }
    };
    const onNotifyUser = (statusType, notificationHandler, message) => {
      notificationHandler({
        messageText: message,
        status: statusType,
      });
    };

    const downloadReport = async (outlet_recovery_id) => {
      console.log("outlet_recovery_id", outlet_recovery_id);
      await triggerSimpleAjax(
        GET_OUTLET_RECOVERY_DOWNLOAD_TABLE_DATA + outlet_recovery_id + "/",
        "POST",
        {},
        (response) => {
          const headers = [
            { label: "User", key: "User" },
            { label: "Project Name", key: "Project Name" },
            { label: "Sub-Project Name", key: "Sub-Project Name" },
            { label: "Auditor Name", key: "Auditor Name" },
            { label: "Date Uploaded", key: "Date Uploaded" },
            { label: "RSID", key: "RSID" },
            { label: "Status", key: "Status" },
          ];
          this.setState(
            {
              ...this.state,
              csvData: response,
              csvHeaderData: headers,
            },
            () => {
              setTimeout(() => {
                this.csvLinkEl.current.link.click();
              });
            }
          );
        }
      );
    };
    const onSubmitAction = () => {
      const { notificationHandler } = this.props;
      console.log("notificationHandler",notificationHandler)

      const neededKeys = [
        "project_id",
        "sub_project_id",
        "auditor_id",
        "rsid_list",
        "date_uploaded",
      ];
      if (Object.keys(this.state.formValues).indexOf("project_id") === -1) {
        this.setState({ project_error: "Please Select Project First" });
        onNotifyUser(
          "FAILED",
          notificationHandler,
          "Something went wrong! Please scroll up to find the individual error message"
        );
      }
      if (Object.keys(this.state.formValues).indexOf("sub_project_id") === -1) {
        this.setState({ sub_project_error: "Please Select Sub Project First" });
        onNotifyUser(
          "FAILED",
          notificationHandler,
          "Something went wrong! Please scroll up to find the individual error message"
        );
      }
      if (Object.keys(this.state.formValues).indexOf("auditor_id") === -1) {
        this.setState({ auditor_error: "Please Select Auditor First" });
        onNotifyUser(
          "FAILED",
          notificationHandler,
          "Something went wrong! Please scroll up to find the individual error message"
        );
      }
      if (Object.keys(this.state.formValues).indexOf("rsid_list") === -1) {
        this.setState({ rsid_assigned_error: "Please Type RSID" });
        onNotifyUser(
          "FAILED",
          notificationHandler,
          "Something went wrong! Please scroll up to find the individual error message"
        );
      }
      if (Object.keys(this.state.formValues).indexOf("date_uploaded") === -1) {
        this.setState({ date_uploaded_error: "Please Select Date First" });
        onNotifyUser(
          "FAILED",
          notificationHandler,
          "Something went wrong! Please scroll up to find the individual error message"
        );
      } else if (neededKeys.every((key) => key in this.state.formValues)) {
        onNotifyUser(
          "SUCCESS",
          notificationHandler,
          "Request Sent Successfully! "
        );
        getOutletRecoveryAddedTableData(this.state.formValues)
          .then((response) => {
            this.triggerTableData();
          })
          .catch((error) => {
            console.log("Error", error);
            if(error.errors.message){
              onNotifyUser("FAILED", notificationHandler, error.errors.message);
            }else{
              onNotifyUser("FAILED", notificationHandler, "Syntax Error: Add a comma as shown in the hint.");
            }
           
            this.setState({ isLoading: false });
          });
      }
    };
    const setSelectedDate = ({ name, dates }) => {
      var date1 = new Date(dates);
      var date2 = new Date();
      var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
      if (diffDays > 10) {
        this.setState({
          ...this.state,
          date_validation_error:
            "Please select a date field that is within the last 10 days.",
        });
      } else {
        this.setState({
          ...this.state,
          [name]: dates,
          date_uploaded_error: "",
          date_validation_error: "",
        });
        this.setState({
          ...this.state,
          formValues: {
            ...this.state.formValues,
            [name]: dates,
          },
        });
      }
    };

    const projectsData = this.state.projectsData.map(({ id, name }) => ({
      value: id,
      label: name,
      name: "project_data",
    }));

    const subprojectsData = this.state.subprojectsData.map(({ id, name }) => ({
      value: id,
      label: name,
      name: "sub_project_data",
    }));
    const auditorData = this.state.auditorData.map(({ id, name }) => ({
      value: id,
      label: name,
      name: "auditor_data",
    }));

    return (
      <Fragment>
        <FormContainer
          padding="0px"
          title="Outlet Field Recovery"
          footerLocation={"60%"}
          onClickAction={onSubmitAction}
          onCancelAction={null}
          actionButtonText="Search"
          backGroundColor={green300}
        >
          <FormRow
            name="Enter Project Name"
            asterisk={true}
            leftSideWidth={"25%"}
            customStyles={{ width: "none" }}
            rightElement={
              <SelectFilter
                rootProps={{ style: { width: "50%" } }}
                props={{
                  name: "project_id",
                  placeholder: "Enter Project Name",
                  onChange: (data) => {
                    this.setState({
                      ...this.state,
                      project_error: "",
                      project_id: data.value,
                      formValues: {
                        ...this.state.formValues,
                        project_id: data.value,
                      },
                    });

                    this.getSubProjectData(data.value);
                  },
                  options: projectsData,
                }}
                warnMessage={{
                  text: this.state.project_error,
                }}
                noOptionMessage="No data available"
              />
            }
          />
          <FormRow
            name="Enter Sub Project Name"
            asterisk={true}
            leftSideWidth={"25%"}
            customStyles={{ width: "none" }}
            rightElement={
              <SelectFilter
                rootProps={{ style: { width: "50%" } }}
                props={{
                  name: "sub_project",
                  placeholder: "Enter Sub Project Name",
                  onChange: (data) => {
                    this.setState({
                      ...this.state,
                      sub_project_error: "",
                      formValues: {
                        ...this.state.formValues,
                        sub_project_id: data.value,
                      },
                    });
                    this.getAuditorData(data.value);
                  },

                  options: subprojectsData,
                }}
                warnMessage={{
                  text: this.state.sub_project_error,
                }}
                noOptionMessage="No data available"
              />
            }
          />
          <FormRow
            name="Enter Auditor Name"
            asterisk={true}
            leftSideWidth={"25%"}
            customStyles={{ width: "none" }}
            rightElement={
              <SelectFilter
                rootProps={{ style: { width: "50%" } }}
                props={{
                  name: "auditor",
                  placeholder: "Enter Auditor Name",
                  onChange: (data) => {
                    this.setState({
                      ...this.state,
                      auditor_error: "",
                      formValues: {
                        ...this.state.formValues,
                        auditor_id: data.value,
                      },
                    });
                  },
                  options: auditorData,
                }}
                warnMessage={{
                  text: this.state.auditor_error,
                }}
                noOptionMessage="No data available"
              />
            }
          />

          <FormRow
            name="Enter RSID"
            asterisk={true}
            leftSideWidth={"25%"}
            rightElement={
              <DashboardTextBox
                type="text"
                value={this.state.rsid_value}
                placeholder="Enter(RSID1,RSID2,RSID3,RSID4,RSID5) (Max 5 Allowed)"
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    rsid_value: e.target.value,
                  });

                  if (e.target.value !== null) {
                    if (e.target.value.split(",").length >= 6) {
                      this.setState({
                        ...this.state,
                        rsid_validation_error: "Only 5 RSIDs are allowed",
                      });
                    } else {
                      onChangeAction(e.target.value);
                    }
                  } else {
                    this.setState({
                      ...this.state,
                      rsid_validation_error: "1 RSID must be seleted",
                    });
                  }
                }}
                warnMessage={{
                  text:
                    this.state.rsid_validation_error === ""
                      ? this.state.rsid_assigned_error
                      : this.state.rsid_validation_error,
                  textAlignMent: "right",
                }}
              />
            }
          />
          <FormRow
            name="Date Uploaded"
            asterisk={true}
            leftSideWidth={"25%"}
            rightElement={
              <DatePicker
                getSelectedDates={setSelectedDate}
                name="date_uploaded"
                singleDateSelection={true}
              >
                <DashboardTextBox
                  type="text"
                  value={this.state.date_uploaded}
                  placeholder={` - - Select Start Date - - `}
                  rightIcon={<CalendarTodayIcon />}
                  // boxWidth="67vh"
                  // boxHeight="30px"
                  warnMessage={{
                    text:
                      this.state.date_validation_error === ""
                        ? this.state.date_uploaded_error
                        : this.state.date_validation_error,
                    position: "right",
                  }}
                />
              </DatePicker>
            }
          />
        </FormContainer>
        <ReactTable
          title="Records"
          rowHeight={"auto"}
          showPagination={true}
          data={this.state.tableData|| []}
          total={this.state.count}
          loading={this.state.isLoading}
          current={this.state.previous ? this.state.previous + 1 : 1}
          previousPage={this.state.previous}
          nextPage={this.state.next}
          pageSize={this.state.pageSize}
          showTotalRecordText={true}
          onPageChange={this.onPageChange}
     
          filters={[]}
          rightHeaderElements={
            <Button
              size="medium"
              bgColor={curiousBlue300}
              color={white}
              onClick={() => {
                this.setState({ isLoading: true });
                this.triggerTableData();
              }}
            >
              <RefreshIcon /> Refresh
            </Button>
          }
          //   loading={isLoading}
          columns={[
            {
              name: "ID",
              key: "id",
              sortable: false,
              width: "10vh",
            },
            {
              name: "User",
              key: "user",
              width: "15vh",
            },
            {
              name: "Project Name",
              key: "project",
            },
            {
              name: "Sub Project Name",
              key: "sub_project",
              width: "18vh",
            },
            {
              name: "Auditor Name",
              key: "auditor",
              width: "15vh",
            },

            {
              name: "Request Date",
              key: "date_uploaded",
              width: "15vh",
            },
            {
              name: "Status",
              key: "status",
              sortable: false,
              width: "20vh",
              render: (value) => {
                let color;
                switch (value) {
                  case "completed":
                    color = completed;
                    break;
                  case "processing":
                    color = processing;
                    break;
                  case "active":
                  case "accepted":
                    color = accepted;
                    break;
                  case "cancelled":
                    color = cancelled;
                    break;
                  case "failed":
                    color = cancelled;
                    break;
                  default:
                    color = completed;
                    break;
                }
                return (
                  <StatusTagStyle bordered borderColor={color} size="small">
                    {value === "active" ? "accepted" : value}
                  </StatusTagStyle>
                );
              },
            },
            {
              name: "Found Records",
              key: "found_records",
              sortable: false,
              width: "15vh",
              wordBreak: "break-all",
            },
            {
              name: "Download",
              key: "download_report",
              sortable: false,
              width: "35vh",
              wordBreak: "break-all",
              render: (value, data) => {
                return (
                 
                  <div>
                    <AnchorText
                      onClick={() => downloadReport(data.id)}
                      target={"_blank"}
                    >
                      Click Here
                    </AnchorText>
                    <CSVLink
                      headers={this.state.csvHeaderData}
                      filename="outlet_recovery.csv"
                      data={this.state.csvData}
                      ref={this.csvLinkEl}
                    />
                  </div>
                );
              },
            },
            {
              name: " ",
              key: "status",
              sortable: false,
              render: (data, { id, total_clean_objects }) => {
                let tickAction = null;
                let cancelAction = null;
                if (data === "accepted") {
                  // tickAction = total_clean_objects
                  //     ? this.triggerCompleted
                  //     : null;
                  tickAction = this.triggerCompleted;
                  cancelAction = this.triggerCancelled;
                }else if(data === "processing"){
                  tickAction = null;
                  cancelAction = this.triggerCancelled;
                }
                
                else if (
                  data === "cancelled" ||
                  data === "completed" ||
                  data === "active" ||
                  data === "failed" ||
                  data === "under_activation"
                ) {
                  return null;
                } else {
                  tickAction = null;
                  cancelAction = this.triggerCancelled;
                }
                return (
                  <Fragment>
                    <div
                      onClick={() => {
                        !this.state.tickOrCancelClicked &&
                          tickAction &&
                          tickAction(id);
                      }}
                    >
                      <CheckBox
                        checked={true}
                        disabled={
                          tickAction
                            ? this.state.tickOrCancelClicked || false
                            : true
                        }
                      />
                    </div>
                    <div
                      onClick={() => {
                        !this.state.tickOrCancelClicked &&
                          cancelAction &&
                          cancelAction(id);
                      }}
                    >
                      <CheckBox
                        checked={true}
                        isTick={false}
                        background={cinnabar300}
                        disabled={
                          cancelAction
                            ? this.state.tickOrCancelClicked || false
                            : true
                        }
                      />
                    </div>
                  </Fragment>
                );
              },
            },

          ]}
        />
        <ConfirmationModal
          open={this.state.open}
          noAction={() => {
            this.setState({
              open: false,
              confirmationAction: null,
              tickOrCancelClicked: false,
            });
          }}
          yesAction={this.state.confirmationAction}
          confirmationText={this.state.confirmationText}
          proceedText={"Do you want to proceed?"}
          closeAction={() => {
            this.setState({
              open: false,
              confirmationAction: null,
              tickOrCancelClicked: false,
            });
          }}
        />
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    notificationHandler: bindActionCreators(triggerNotification, dispatch),
  };
};

export default withLastLocation(
  connect(null, mapDispatchToProps)(OutletFieldRecovery)
);
// export default withLastLocation(OutletFieldRecovery);

